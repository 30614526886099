import { useMemo } from "react";
import { Menu, Box, Text } from "grommet";
import { FormDown, Checkmark } from "grommet-icons";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
// import BadgeIcon from "@organisms/badges/icon";
import { networks } from "@organisms/addCoin/validate";
import types from "./types";

const blankNetwork = {
  name: "Network",
  color: "dark-4",
  description: "Badge",
};

const typeKeysWithEmpty = [{ label: "", value: "" }, ...networks];

const Network = ({ type }) => {
  const large = useIsLarge();
  const name = types[type]
    ? type === types[type].name
      ? type
      : `(${type}) ${types[type].name}`
    : blankNetwork["name"];

  return (
    <Box direction="row" gap="small">
      <Text
        color={type ? "light-4" : blankNetwork.color}
        size={large ? "" : "16px"}
        truncate
      >
        {name}
      </Text>
    </Box>
  );
};

export default function Dropdown({ network, onChange }) {
  const large = useIsLarge();

  const items = useMemo(
    () =>
      (!network ? networks : typeKeysWithEmpty).map(({ value, label }) => {
        const name = types[value] ? label : blankNetwork["name"];
        return {
          label: (
            <Box flex alignSelf="center" justify="between" direction="row">
              <Text
                color={value ? "" : blankNetwork.color}
                size={large ? "" : "16px"}
              >
                {name}
              </Text>
              {value === network && <Checkmark color="light-4" />}
            </Box>
          ),
          onClick: () => onChange(value),
        };
      }),
    [network, onChange]
  );

  return (
    <Menu
      plain
      items={items}
      fill={large ? false : "horizontal"}
      dropProps={{
        elevation: "none",
        background: "#26272C",
        border: true,
        round: "large",
        stretch: "align",
        width: large ? "300px" : "100%",
        margin: { top: "xxsmall" },
      }}
    >
      <Box
        direction="row"
        gap="small"
        width={large ? "214px" : "100%"}
        justify="between"
        align="center"
        pad={
          large
            ? { horizontal: "medium", vertical: "xsmall" }
            : { horizontal: "large", vertical: "medium" }
        }
        round="large"
        border
      >
        <Network type={network} />
        <FormDown color="dark-6" />
      </Box>
    </Menu>
  );
}
