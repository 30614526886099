export default {
  firebaseCredential: {
    type: "service_account",
    project_id: "coinscopeassets",
    private_key_id: process.env.FIREBASE_PRIVATE_KEY_ID,
    private_key: `-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+1kpaevUelrS4\nB/9cYcz4KywU/7BOjqZ9vCjeR0N77doEKOE7UBPDx4IajlaliCvFgLERCnowbfOK\n5VPg6j100h5JoIe6mfhGzYGom8+I5DF6UJuAA1nkYobh9G1ITBQqCbK8hMJ7bxV2\nm35WneStsMGV8wljP2EDTj583fF3GS/j/8csz/qLCRJmN6CoQ+bsOVZ2hORYEVNw\nQe9bOGjyS0SQEq4aVXW3TGsLZ96KyP3szEn2eZMDD20XWIt9wL48kG7o8Bns1Bnm\nZ2DIZsbdM+wzVRZdNS+bI/ZhOUiRT2u8/dxKqCNH90CB0Q3TQU0Lh73GLq2eN1O8\nrQ6nVKTpAgMBAAECggEAB6hGOtPc4AmrXghcHmiCNqGs1s7c/oSfeO4YNGBm/Hhd\nG3ZJy1J5kzfUfZz2gb/cBG8XK8LgMkScgBdBmAXDdNuK1ujNFBdiLJ0fCxVCwjsT\nIoBM168gEpeYJ/A3Llkhz1Ss9P7dZYyIhPDU+e7DTUDevlXj1jVQfuHLL2whveXq\nsDMlbNtRcSJ3EJz5lRrc52c25dE5YmL1PNHoQm6Y+RUOU75ueCvu4Fe4Y59V+4sj\napXWZOiIB4do04yo31FyTyHFTsfpkTOR56Cin9COCSbKP/S4GLAGqCPZgCjmpmm2\n1RkYE5F19C3Li+nS+wAKvFpybne7BT+j4G80ukxfXQKBgQDxKA79DRG4ENWa3QVF\nyNAfuUIRYvBsDa61XomXJbNrwqhJMyUNlEKF+IsXeSp1QjpfgYz0OHWJv0xoCbo5\nce4XDzC1rscUxwvs+jEBrhrPV9eUYkXEq+Y6TiN9wkEqUqolzsGgFVVLymuckQeN\n3o3sShEO8fq7HJegi0VNHzdbFwKBgQDKlVdFf+LFA1hHTqcPOTsvHH+xnpW/joel\nt3Y8VW6XY2ynG/QxlR/IOfFxnoho5+BEfmaaCtUvINXGGo0c/gAeopvgPlt6ku5k\nOy0ZQikhP4Mlm79CXnN2sjrRunr53q/s58jsJ2pAocB/AhRXJorGpLozTJPa6sNi\nD2OvFVP//wKBgDIfBkZ30ef0r8CZEUB4xTDiuyWL77Y1fcs6U9RzRIAwG/Piyl+x\nIcZgauA9EaL/WcyBy37ywRMZDFNPOkI4OKjYQmJwafYajJL7SPY1bmMLucWgSyne\nJGKX3O6FUi/usGhdOYcBCBJRht4qkmxdBhhHKJZ3mooLHkSCzauCwmmPAoGAIO7S\nNywpE1oN2dnBcsodAvWhFKDxOyjSiHAguoIw7k9tdGUo936Eoio5Q/0/s74uSTQ7\nPcOREI5H8mKSmin4nwJUUMoSWQTV2Fl+ZWLKCOuoSE7fcsyj/KHQyhOEtmYrN1oe\n4bUuor8o6fzOjbRoTw6sbnZ+etI7XQ+fZvESrMUCgYBLiiWBJQt8p0d53+lcLhpb\nR2DajmBco/XkngWDQnvXScFUfaRsAa31VG0MeY7oBTTVPJdrLqO3fT7NvrPt+OmT\nM4Z4hKqpM3ODkPGhXhX6SUj0Jwet9pVj0Ql5MDrKH3dTbAFM1tr6K1YtejHbWdn1\npmAxRCpJK5O3sEITZMT34w==\n-----END PRIVATE KEY-----\n`,
    client_email:
      "firebase-adminsdk-ujw8f@coinscopeassets.iam.gserviceaccount.com",
    client_id: "106646739572779827448",
    auth_uri: "https://accounts.google.com/o/oauth2/auth",
    token_uri: "https://oauth2.googleapis.com/token",
    auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
    client_x509_cert_url:
      "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-ujw8f%40coinscopeassets.iam.gserviceaccount.com",
    universe_domain: "googleapis.com",
  },

  promotionTypes: ["Promotion List", "Banner Ads", "Wide Banner", "Popup"],
  promotionDateTypes: ["Promotion List", "Banner Ads", "Wide Banner", "Popup"],
  priceByType: {
    "Promotion List": 0.2,
    "Banner Ads": 0.3,
    "Wide Banner": 0.7,
    Popup: 1,
    AMA: 1.5,
  },
  coinscopeHoldingDiscount: "300,000",
  registeredUsers: 2616112,
  registeredUsersFormatted: "2,6 Million",
  dailyUsers: 50000,
  dailyUsersFormatted: "50,000",
  totalAudits: 2900,
  totalAirdrops: 160,
  telegramUsers: 14000,
  newsletterUsers: 19000,

  bannerWidth: 320,
  bannerHeight: 128,
  wideBannerWidth: 1200,
  wideBannerHeight: 90,

  buybackFee: 10,
  minTokensToHoldAirdrop: 50000,
};
