import networkTypes from "@cybertools/ui/es/organisms/networks/types/basic";
import { listedNetworks } from "@cybertools/ui/es/organisms/networks/supported";
import currencies from "./presale/currencies";
import presaleTypes from "./presale/types";

export const required = new Set([
  "name",
  "symbol",
  "icon",
  "description",
  "network",
]);

export const autofill = {
  name: "SafeWolf",
  symbol: "SW",
  icon: "https://safewolf.io/wp-content/uploads/2021/05/cropped-icon-512-safewolf.png",
  address: "0xc845341377c68b8003485036c3163b8dbcf8ac11",
  website: "https://safewolf.io/",
  telegram: "https://t.me/Safewolfgroup",
  twitter: "https://twitter.com/Safewolftoken",
  discord: "https://discord.gg/pqXp4Zsrxv",
  description:
    "SAFEWOLF is a community driven project built on Binance Smart Chain #BSC. Two functions occur during each trade: Reflection & Burn. SAFEWOLF brings the most revolutionary mechanism to BSC ecosystem",
  deployedAtDay: "20",
  deployedAtMonth: "05",
  deployedAtYear: "2021",
  network: "BSC",
  presaleStartDay: "03",
  presaleStartMonth: "02",
  presaleStartYear: "2024",
  presaleEndDay: "04",
  presaleEndMonth: "02",
  presaleEndYear: "2024",
  presaleLink:
    "https://www.pinksale.finance/launchpad/0x26A979DE642a323272Ab86190EE3A84B95b5F72c?chain=BSC",
  presaleWhitelist: "https://example.com",
  presaleWhitelistLink: "https://www.pinksale.finance",
  presaleSoftcap: 5,
  presaleHardcap: 800,
};

export const maxSizes = {
  name: 32,
  symbol: 32,
  icon: 512,
  description: 4096,
  website: 512,
  telegram: 512,
  twitter: 512,
  discord: 512,
  reddit: 512,
  customSwap: 512,
  customChart: 512,
};

const tryTo = (func) => {
  try {
    return Boolean(func());
  } catch (e) {
    return false;
  }
};

const validateUrl = (value) =>
  tryTo(() => new URL(value).protocol === "https:");

const defaultValidator = { validate: validateUrl, error: "Invalid URL" };

const makeHostValidate = (hostnames, domains) => (value) =>
  tryTo(() => {
    const { hostname, protocol, pathname } = new URL(value);
    const [firstHost, secondHost] = hostname.split(".").splice(-2);

    return (
      (hostnames.includes(firstHost) ||
        (domains && domains.includes(`${firstHost}.${secondHost}`))) &&
      protocol === "https:" &&
      pathname.length > 2
    );
  });

export const validator = {
  website: defaultValidator,
  presaleLink: defaultValidator,
  presaleWhitelistLink: defaultValidator,
  customSwap: defaultValidator,
  customChart: defaultValidator,
  icon: defaultValidator,
  telegram: {
    validate: makeHostValidate(["telegram"], ["t.me"]),
    error: "Invalid Telegram URL",
  },
  telegramGroup: {
    validate: makeHostValidate(["telegram"], ["t.me"]),
    error: "Invalid Telegram URL",
  },
  twitter: {
    validate: makeHostValidate(["twitter", "x"], ["t.co"]),
    error: "Invalid Twitter URL",
  },
  discord: {
    validate: makeHostValidate(["discord", "dsc"]),
    error: "Invalid Discord URL",
  },
  reddit: {
    validate: makeHostValidate(["reddit"]),
    error: "Invalid Reddit URL",
  },
  facebook: {
    validate: makeHostValidate(["facebook", "fb"]),
    error: "Invalid Facebook URL",
  },
  instagram: {
    validate: makeHostValidate(["instagram"]),
    error: "Invalid Instagram URL",
  },
  linkedin: {
    validate: makeHostValidate(["linkedin"]),
    error: "Invalid LinkedIn URL",
  },
  medium: {
    validate: makeHostValidate(["medium"]),
    error: "Invalid LinkedIn URL",
  },
  github: {
    validate: makeHostValidate(["github"]),
    error: "Invalid Github URL",
  },
  bitcointalk: {
    validate: makeHostValidate(["bitcointalk"]),
    error: "Invalid Bitcointalk URL",
  },
  youtube: {
    validate: makeHostValidate(["youtube", "youtu"]),
    error: "Invalid Youtube URL",
  },
  cmc: {
    validate: makeHostValidate(["coinmarketcap"]),
    error: "Invalid CoinMarketCap URL",
  },
  coingecko: {
    validate: makeHostValidate(["coingecko"]),
    error: "Invalid CoinGecko URL",
  },
};

export const networks = listedNetworks.map((key) => ({
  label:
    key === networkTypes[key].name ? key : `(${key}) ${networkTypes[key].name}`,
  value: key,
}));

const attrs = {
  name: "",
  symbol: "",
  icon: "",
  address: "",
  website: "",
  telegram: "",
  twitter: "",
  reddit: "",
  discord: "",
  facebook: "",
  instagram: "",
  telegramGroup: "",
  cmc: "",
  coingecko: "",
  description: "",
  network: "BSC",
  presale: false,
  presaleLink: "",
  presaleWhitelistLink: "",
  presaleCurrency: "",
  presaleType: "",
  customSwap: "",
  customChart: "",
  presaleSoftcap: 0,
  presaleHardcap: 0,
  deployedAt: null,
  createdAt: null,
  launchedAt: null,
  presaleStartsAt: null,
  presaleEndsAt: null,
};

export const getCoinSchema = () => ({
  ...attrs,
  recentVotes: [],
  totalRecentVotes: 0,
  totalVotes: 0,
  slug: "",
  verified: false,
  marketcap: 0,
});

export default function validate(coin) {
  const { presaleStartsAt, presale, deployedAt } = coin;

  const errors = Object.keys(attrs).reduce(
    (h, name) => {
      const value = coin[name];

      if (!value && required.has(name)) return { ...h, [name]: "Required" };

      if (!value) return h;

      if (name in maxSizes && value.length > maxSizes[name])
        return {
          ...h,
          [name]: `Should be less than ${maxSizes[name]} characters`,
        };

      if (validator[name] && !validator[name].validate(value))
        return { ...h, [name]: validator[name].error };

      if (name === "network" && !listedNetworks.includes(value))
        return { ...h, [name]: `${listedNetworks.join(", ")} are supported` };

      if (name === "address") {
        const validator =
          coin.network in networkTypes
            ? networkTypes[coin.network].validator
            : networkTypes.ETH.validator;
        const error = validator(value);
        if (error) return { ...h, [name]: error };
      }

      if (presale) {
        if (name === "presaleCurrency" && !currencies.includes(value))
          return { ...h, [name]: `${currencies.join(", ")} are supported` };

        if (name === "presaleType" && !presaleTypes.includes(value))
          return { ...h, [name]: `${presaleTypes.join(", ")} are supported` };

        if (name === "presaleHardcap") {
          if (value < 0) return { ...h, presaleHardcap: "Invalid number" };

          const { presaleSoftcap } = coin;

          if (presaleSoftcap && presaleSoftcap > value)
            return { ...h, presaleHardcap: "Should be more than the Softcap" };
        }

        if (name === "presaleSoftcap" && value < 0)
          return { ...h, presaleSoftcap: "Invalid number" };

        if (name === "presaleStartsAt") {
          const { presaleEndsAt } = coin;

          if (presaleEndsAt && value.getTime() > presaleEndsAt.getTime())
            return {
              ...h,
              presaleEndsAt: "Should be more than the Presale Start Date",
            };
        }
      }

      return h;
    },
    {
      ...(presale &&
        !deployedAt &&
        !presaleStartsAt && {
          deployedAt: "Fill Launch Date or Presale Start Date",
        }),
    }
  );

  return errors;
}
