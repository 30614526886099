import bscImg from "@cybertools/ui/assets/images/networks/bsc.png";
import ethImg from "@cybertools/ui/assets/images/networks/eth.png";
import maticImg from "@cybertools/ui/assets/images/networks/matic.png";
import trxImg from "@cybertools/ui/assets/images/networks/trx.png";
import xrpImg from "@cybertools/ui/assets/images/networks/xrp.png";
import kccImg from "@cybertools/ui/assets/images/networks/kcc.png";
import solImg from "@cybertools/ui/assets/images/networks/sol.png";
import avaxImg from "@cybertools/ui/assets/images/networks/avax.png";
import oneImg from "@cybertools/ui/assets/images/networks/one.png";
import fantomImg from "@cybertools/ui/assets/images/networks/fantom.png";
import ameImg from "@cybertools/ui/assets/images/networks/ame.png";
import vlxImg from "@cybertools/ui/assets/images/networks/vlx.png";
import croImg from "@cybertools/ui/assets/images/networks/cro.png";
import htImg from "@cybertools/ui/assets/images/networks/ht.png";
import roseImg from "@cybertools/ui/assets/images/networks/rose.png";
import movrImg from "@cybertools/ui/assets/images/networks/movr.svg";
import glmrImg from "@cybertools/ui/assets/images/networks/glmr.svg";
import celoImg from "@cybertools/ui/assets/images/networks/celo.png";
import dogeImg from "@cybertools/ui/assets/images/networks/doge.png";
import arbitrumImg from "@cybertools/ui/assets/images/networks/arbitrum.svg";
import klayImg from "@cybertools/ui/assets/images/networks/klay.svg";
import etcImg from "@cybertools/ui/assets/images/networks/etc.svg";
import xdaiImg from "@cybertools/ui/assets/images/networks/xdai.svg";
import optimismImg from "@cybertools/ui/assets/images/networks/optimism.svg";
import briseImg from "@cybertools/ui/assets/images/networks/brise.png";
import loopImg from "@cybertools/ui/assets/images/networks/loop.png";
import zkSyncImg from "@cybertools/ui/assets/images/networks/zkSync.png";
import telosImg from "@cybertools/ui/assets/images/networks/telos.png";
import baseImg from "@cybertools/ui/assets/images/networks/base.png";
import blastImg from "@cybertools/ui/assets/images/networks/blast.png";
import areonImg from "@cybertools/ui/assets/images/networks/areon.svg";
import lineaImg from "@cybertools/ui/assets/images/networks/linea.png";
import scrollImg from "@cybertools/ui/assets/images/networks/scroll.png";
import taikoImg from "@cybertools/ui/assets/images/networks/taiko.png";
import mantleImg from "@cybertools/ui/assets/images/networks/mantle.png";
import coreImg from "@cybertools/ui/assets/images/networks/core.png";
import fuseImg from "@cybertools/ui/assets/images/networks/fuse.png";
import wanchainImg from "@cybertools/ui/assets/images/networks/wanchain.png";
import tonImg from "@cybertools/ui/assets/images/networks/ton.png";
import xlayerImg from "@cybertools/ui/assets/images/networks/xlayer.png";
import xdcImg from "@cybertools/ui/assets/images/networks/xdc.png";
import basic from "@cybertools/ui/es/organisms/networks/types/basic";

// ROPSTEN, RINKEBY are ignored

const ethCreateTokenFee = 0.01;
const ethCreateAirdropFee = 0.005;
const bnbCreateTokenFee = 0.2;
const bnbCreateAirdropFee = 0.1;
const bnbWinnersAirdropFee = 0.01;

const networks = {
  ...basic,
  BSC: {
    ...basic.BSC,
    icon: bscImg,
    createTokenFee: bnbCreateTokenFee,
    createAirdropFee: bnbCreateAirdropFee,
    winnersAirdropFee: bnbWinnersAirdropFee,
  },
  ETH: {
    ...basic.ETH,
    icon: ethImg,
    createTokenFee: ethCreateTokenFee,
    createAirdropFee: ethCreateAirdropFee,
    winnersAirdropFee: 0.001,
  },
  MATIC: {
    ...basic.MATIC,
    icon: maticImg,
    createAirdropFee: 53,
    winnersAirdropFee: 0,
    createTokenFee: 106,
  },
  AVAX: {
    ...basic.AVAX,
    icon: avaxImg,
    createAirdropFee: 0.85,
    winnersAirdropFee: 0,
    createTokenFee: 1.7,
  },
  DOGE: {
    ...basic.DOGE,
    icon: dogeImg,
    createAirdropFee: 383,
    winnersAirdropFee: 0,
    createTokenFee: 766,
  },
  ONE: {
    ...basic.ONE,
    icon: oneImg,
    createAirdropFee: 1861,
    winnersAirdropFee: 0,
    createTokenFee: 3722,
  },
  ARBITRUM: {
    ...basic.ARBITRUM,
    icon: arbitrumImg,
    createAirdropFee: ethCreateAirdropFee,
    winnersAirdropFee: 0,
    createTokenFee: ethCreateTokenFee,
  },
  BLAST: {
    ...basic.BLAST,
    icon: blastImg,
    createAirdropFee: 1,
    winnersAirdropFee: 0,
    createTokenFee: 1,
  },
  KLAY: {
    ...basic.KLAY,
    icon: klayImg,
    createAirdropFee: 212,
    winnersAirdropFee: 0,
    createTokenFee: 424,
  },
  TELOS: {
    ...basic.TELOS,
    icon: telosImg,
    createAirdropFee: 169,
    winnersAirdropFee: 0,
    createTokenFee: 338,
  },
  TRX: {
    ...basic.TRX,
    icon: trxImg,
    createAirdropFee: 420,
    winnersAirdropFee: 0,
    createTokenFee: 840,
  },
  XRP: {
    ...basic.XRP,
    icon: xrpImg,
    createAirdropFee: 84,
    winnersAirdropFee: 0,
    createTokenFee: 168,
  },
  KCC: {
    ...basic.KCC,
    icon: kccImg,
    createAirdropFee: 4,
    winnersAirdropFee: 0,
    createTokenFee: 8,
  },
  SOL: {
    ...basic.SOL,
    icon: solImg,
    createAirdropFee: 0.2,
    winnersAirdropFee: 0,
    createTokenFee: 0.5,
  },
  FTM: {
    ...basic.FTM,
    icon: fantomImg,
    createAirdropFee: 55,
    winnersAirdropFee: 0,
    createTokenFee: 110,
  },
  AME: {
    ...basic.AME,
    icon: ameImg,
    createAirdropFee: 11039,
    winnersAirdropFee: 0,
  },
  VLX: {
    ...basic.VLX,
    icon: vlxImg,
    createAirdropFee: 2558,
    winnersAirdropFee: 0,
    createTokenFee: 5116,
  },
  CRO: {
    ...basic.CRO,
    icon: croImg,
    createAirdropFee: 400,
    winnersAirdropFee: 0,
    createTokenFee: 800,
  },
  HT: {
    ...basic.HT,
    icon: htImg,
    createTokenFee: 10,
    createAirdropFee: 5,
    winnersAirdropFee: 0,
  },
  ROSE: {
    ...basic.ROSE,
    icon: roseImg,
    createAirdropFee: 378,
    winnersAirdropFee: 0,
    createTokenFee: 756,
  },
  MOVR: {
    ...basic.MOVR,
    icon: movrImg,
    createAirdropFee: 2.5,
    winnersAirdropFee: 0,
    createTokenFee: 5,
  },
  GLMR: {
    ...basic.GLMR,
    icon: glmrImg,
    createAirdropFee: 100,
    winnersAirdropFee: 0,
    createTokenFee: 200,
  },
  CELO: {
    ...basic.CELO,
    icon: celoImg,
    createAirdropFee: 47,
    winnersAirdropFee: 0,
    createTokenFee: 94,
  },
  ETC: {
    ...basic.ETC,
    icon: etcImg,
    createAirdropFee: 1.7,
    winnersAirdropFee: 0,
    createTokenFee: 3.5,
  },
  XDAI: {
    ...basic.XDAI,
    icon: xdaiImg,
    createAirdropFee: 53,
    winnersAirdropFee: 0,
    createTokenFee: 106,
  },
  OPTIMISM: {
    ...basic.OPTIMISM,
    icon: optimismImg,
    createAirdropFee: ethCreateAirdropFee,
    winnersAirdropFee: 0,
    createTokenFee: ethCreateTokenFee,
  },
  BRISE: {
    ...basic.BRISE,
    icon: briseImg,
    createAirdropFee: 211102291,
    winnersAirdropFee: 0,
    createTokenFee: 422204583,
  },
  LOOP: {
    ...basic.LOOP,
    icon: loopImg,
    createAirdropFee: 800,
    winnersAirdropFee: 0,
    createTokenFee: 1600,
  },
  ZKSYNC: {
    ...basic.ZKSYNC,
    icon: zkSyncImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0,
  },
  BASE: {
    ...basic.BASE,
    icon: baseImg,
    createAirdropFee: ethCreateAirdropFee,
    winnersAirdropFee: 0,
    createTokenFee: ethCreateTokenFee,
  },
  AREON: {
    ...basic.AREON,
    icon: areonImg,
    createAirdropFee: ethCreateAirdropFee,
    winnersAirdropFee: 0,
    createTokenFee: ethCreateTokenFee,
  },
  LINEA: {
    ...basic.LINEA,
    icon: lineaImg,
    createAirdropFee: ethCreateAirdropFee,
    winnersAirdropFee: 0,
    createTokenFee: ethCreateTokenFee,
  },
  SCROLL: {
    ...basic.SCROLL,
    icon: scrollImg,
    createAirdropFee: ethCreateAirdropFee,
    winnersAirdropFee: 0,
    createTokenFee: ethCreateTokenFee,
  },
  TAIKO: {
    ...basic.TAIKO,
    icon: taikoImg,
    createAirdropFee: ethCreateAirdropFee,
    winnersAirdropFee: 0,
    createTokenFee: ethCreateTokenFee,
  },
  OP_BNB: {
    ...basic.OP_BNB,
    icon: bscImg,
    createTokenFee: bnbCreateTokenFee,
    createAirdropFee: bnbCreateAirdropFee,
    winnersAirdropFee: bnbWinnersAirdropFee,
  },
  MANTLE: {
    ...basic.MANTLE,
    icon: mantleImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0,
  },
  CORE: {
    ...basic.CORE,
    icon: coreImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0,
  },
  FUSE: {
    ...basic.FUSE,
    icon: fuseImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0,
  },
  WANCHAIN: {
    ...basic.WANCHAIN,
    icon: wanchainImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0,
  },
  XDC: {
    ...basic.XDC,
    icon: xdcImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0,
  },
  TON: {
    ...basic.TON,
    icon: tonImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0,
  },
  X_LAYER: {
    ...basic.X_LAYER,
    icon: xlayerImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0,
  },
  BSC_TESTNET: {
    ...basic.BSC_TESTNET,
    icon: bscImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0.01,
  },
  SEPOLIA: {
    ...basic.SEPOLIA,
    icon: ethImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0.01,
  },
  GOERLI: {
    ...basic.GOERLI,
    icon: ethImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0.001,
  },
  AVAX_TESTNET: {
    ...basic.AVAX_TESTNET,
    icon: avaxImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0.01,
  },
  OPTIMISTIC_GOERLI: {
    ...basic.OPTIMISTIC_GOERLI,
    icon: optimismImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0.01,
  },
  ARBITRUM_GOERLI: {
    ...basic.ARBITRUM_GOERLI,
    icon: arbitrumImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0.01,
  },
  FANTOM_TESTNET: {
    ...basic.FANTOM_TESTNET,
    icon: fantomImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0.01,
  },
  POLYGON_MUMBAI: {
    ...basic.POLYGON_MUMBAI,
    icon: maticImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0.01,
  },
  ZKSYNC_TESTNET: {
    ...basic.ZKSYNC_TESTNET,
    icon: zkSyncImg,
    createAirdropFee: 0,
    winnersAirdropFee: 0,
    createTokenFee: 0,
  },
};

export const networkById = Object.keys(networks)
  .filter((id) => networks[id].chainId)
  .reduce(
    (acc, id) => ({
      ...acc,
      [networks[id].chainId]: { ...networks[id], id },
    }),
    {}
  );

export default networks;
