import { useMemo } from "react";
import { Menu, Box } from "grommet";
import { FormDown, Checkmark } from "grommet-icons";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import BadgeIcon from "@organisms/badges/icon";
import Text from "components/text";
import types, { applicableBadges } from "./types";

const blankBadge = {
  title: "Audit, KYC, badges",
  color: "dark-4",
  description: "Badge",
};

const typeKeysWithEmpty = ["", ...applicableBadges];

const Badge = ({ type }) => {
  const large = useIsLarge();

  const { title } = types[type] || blankBadge;

  return (
    <Box direction="row" gap="small">
      {type && <BadgeIcon type={type} size={large ? "medium" : "20px"} />}
      <Text
        color={type ? "light-4" : blankBadge.color}
        size={large ? "" : "16px"}
      >
        {title}
      </Text>
    </Box>
  );
};

export default function Dropdown({ value, onChange }) {
  const large = useIsLarge();

  const items = useMemo(
    () =>
      (!value ? applicableBadges : typeKeysWithEmpty).map((key) => {
        const { title } = types[key] || blankBadge;

        return {
          label: (
            <Box flex alignSelf="center" justify="between" direction="row">
              <Text
                color={key ? "" : blankBadge.color}
                size={large ? "" : "small"}
              >
                {title}
              </Text>
              {key === value && <Checkmark color="light-4" />}
            </Box>
          ),
          margin: key === "audit" ? { top: "xsmall" } : "none",
          onClick: () => onChange(key),
          ...(key && {
            icon: (
              <Box pad={{ horizontal: "small" }}>
                <BadgeIcon type={key} />
              </Box>
            ),
          }),
        };
      }),
    [value, onChange]
  );

  return (
    <Menu
      plain
      items={items}
      fill={large ? false : "horizontal"}
      dropProps={{
        elevation: "none",
        background: "#26272C",
        border: true,
        round: "large",
        stretch: "align",
        width: large ? "300px" : "100%",
        margin: { top: "xxsmall" },
      }}
    >
      <Box
        direction="row"
        gap="small"
        width={large ? "214px" : "100%"}
        justify="between"
        align="center"
        pad={
          large
            ? { horizontal: "medium", vertical: "xsmall" }
            : { horizontal: "large", vertical: "medium" }
        }
        round="large"
        border
      >
        <Badge type={value} />
        <FormDown color="dark-6" />
      </Box>
    </Menu>
  );
}
